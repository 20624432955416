@use "sass:map";
@use "@angular/material" as mat;

$font-family-header: "Open Sans", serif;
$font-family-body: "Barlow", sans-serif;
$font-family-import-url: "https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&family=Open+Sans:wght@400;500;600&display=swap";

$mat-headline: mat.define-typography-level(24px, 32px, 400);

$mat-title: mat.define-typography-level(
  $font-family: $font-family-header,
  $font-size: 40px,
  $font-weight: 600,
  $line-height: 40px,
  $letter-spacing: 0,
);

$mat-subheading-1: mat.define-typography-level(
  $font-family: $font-family-header,
  $font-size: 20px,
  $font-weight: 600,
  $line-height: 32px,
  $letter-spacing: 0,
);

$mat-subheading-2: mat.define-typography-level(
  $font-family: $font-family-header,
  $font-size: 18px,
  $font-weight: 600,
  $line-height: 32px,
  $letter-spacing: 0,
);

$mat-body-1: mat.define-typography-level(16px, 22px, 400);
$mat-body-2: mat.define-typography-level(16px, 22px, 600);
$mat-button: mat.define-typography-level(16px, 22px, 600);

$mat-primary-colors: (
  50 : #ebfdf3,
  100 : #c6f6d7,
  200 : #9feebb,
  300 : #78e59e,
  400 : #5edb88,
  500 : #45d172,
  600 : #3ecb69,
  700 : #35c25e,
  800 : #2db954,
  900 : #1fa943,
  A100 : #ffffff,
  A200 : #c8ffd4,
  A400 : #96ffaf,
  A700 : #7dff9d,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-accent-colors: (
  50: #eaeae9,
  100: #cbcbc9,
  200: #a8a8a5,
  300: #858581,
  400: #6a6a66,
  500: #50504b,
  600: #494944,
  700: #40403b,
  800: #373733,
  900: #272723,
  A100: #f6f67e,
  A200: #f2f24e,
  A400: #ffff0e,
  A700: #f4f400,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);


$my-primary: mat.define-palette($mat-primary-colors, 400);
$my-accent: mat.define-palette($mat-accent-colors, 500);
$my-warn: mat.define-palette(mat.$red-palette, 500);
$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));
