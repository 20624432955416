*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  background-color: $background-color;
  font-family: $font-family-body;
  color: $text-color;
  padding: 0;
  margin: 0;
}
