dl.settings-list {
  dt {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 2px;
  }

  dd {
    margin-left: 1em;
    margin-bottom: 1em;
  }
}
