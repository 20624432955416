.app-loading {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
  z-index: 999;

  .loading-text {
    color: #32322d;
    font-family: $font-family-body;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }
}
.app-loading .loading-spinner,
.app-loading .loading-spinner:before,
.app-loading .loading-spinner:after {
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.app-loading .loading-spinner {
  position: relative;
  width: 100px;
  height: 100px;
  animation: spin0 4s linear infinite;
}

.app-loading .loading-spinner:before {
  border: 10px solid #00a2a3;
  border-bottom: 10px solid #6dffb0;
  border-left: 10px solid #6dffb0;
  animation: spin1 5s linear infinite;
}

.app-loading .loading-spinner:after {
  border: 10px solid #00a2a3;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  animation: spin2 5s linear infinite;
}

@keyframes spin0 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin1 {
  20% {
    transform: rotate(150deg);
  }
  40% {
    transform: rotate(300deg);
  }
  80% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(-30deg);
  }
  30% {
    transform: rotate(-30deg);
    border-color: transparent transparent #b69f63 #b69f63;
  }
  30% {
    border-color: #02b7ff #02b7ff transparent transparent;
  }
  30% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(120deg);
    border-color: #b69f63 #b69f63 transparent transparent;
  }
  30% {
    border-color: transparent transparent #b69f63 #b69f63;
  }
  30% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
