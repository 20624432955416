/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@use 'styles/themes/light-theme' as light;
@use 'styles/themes/dark-theme' as dark;
@use 'styles/themes/angular-defined-themes' as angular;
@import './styles/index';

.mat-tooltip {
  padding: 2px;
  font-size: 14px;
}

color-picker .color-picker {
  z-index: 1001;
}

.overflow-auto {
  overflow: auto;
}
button[mat-icon-button] {
  &.active, &:hover {
    color: var(--primary-contrast-color);
    background-color: var(--primary-color);
  }
}

.light-theme {
  @include mat.all-component-themes(light.$my-theme);
}
.dark-theme {
  @include mat.all-component-themes(dark.$my-theme);
}
.cyan-theme {
  @include mat.all-component-themes(angular.$cyan-theme);
}
.azure-theme {
  @include mat.all-component-themes(angular.$azure-theme);
}
