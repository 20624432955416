@use "@angular/material" as mat;


mat-list.mat-list {
  mat-list-item.mat-list-item {
    cursor: pointer;
    &.active {
      color: var(--primary-contrast-color);
      background-color: rgba(var(--primary-color-rgb), 0.3);
    }
    &:hover {
      background-color: rgba(var(--primary-color-rgb), 0.4);
    }
  }
}
