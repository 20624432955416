@use "sass:map";
@use "@angular/material" as mat;
@use 'themes/angular-defined-themes' as angular;
@use 'themes/light-theme' as light-theme;
@use 'themes/dark-theme' as dark-theme;
$background-color: #f5f5f3;
$text-color: #32322d;
$error-color: #e70000;
$ruler-color: #32322d;
$form-field-background: #f5f5f3;
$use-brand-colors-for-form-field: true;

$dark-primary: mat.get-color-from-palette(dark-theme.$my-primary, 500);
$light-primary: mat.get-color-from-palette(light-theme.$my-primary, 500);
$azure-primary: mat.get-color-from-palette(angular.$azure-primary-colors, 500);
$cyan-primary: mat.get-color-from-palette(angular.$cyan-primary-colors, 500);
$dark-accent: mat.get-color-from-palette(dark-theme.$my-accent, 500);
$light-accent: mat.get-color-from-palette(light-theme.$my-accent, 500);
$azure-accent: mat.get-color-from-palette(angular.$azure-accent-colors, 500);
$cyan-accent: mat.get-color-from-palette(angular.$orange-accent-colors, 500);


$dark-brown:    #332404;
$light-brown:   #b69f63;
$rust:          #b93d09;
$signal-red:    #fa4e44;
$pig:           #eec2dc;
$orange:        #e75600;
$yellow:        #fff167;
$meeting-green: #102a15;
$signal-green:  #5ad759;
$old-green:     #b5ec93;
$flash-green:   #6dffb0;
$green:         #2db47d;
$turquoise:     #00a2a3;
$light-blue:    #2ae9f8;
$blue:          #02b7ff;
$bank-blue:     #043854;
$dark-blue:     #061d46;
$white:         #ffffff;
$black:         #000000;
$aubergine:     #2f100d;
