$spacings: (
  "0": 0,
  "1": .25em,
  "2": .5em,
  "3": 1em,
);

$edges: (
  "t": "top",
  "l": "left",
  "r": "right",
  "b": "bottom"
);

@each $name, $edge in  $edges {
  @each $lvl, $value in $spacings {
    .m#{$name}-#{$lvl} { margin-#{$edge}: $value !important; }
    .p#{$name}-#{$lvl} { padding-#{$edge}: $value !important; }
  }
}
@each $lvl, $value in $spacings {
  .m-#{$lvl} { margin: $value !important; }
  .p-#{$lvl} { padding: $value !important; }
  .mx-#{$lvl} { margin-left: $value !important; margin-right: $value !important; }
  .px-#{$lvl} { padding-left: $value !important; padding-right: $value !important; }
  .my-#{$lvl} { margin-top: $value !important; margin-bottom: $value !important; }
  .py-#{$lvl} { padding-top: $value !important; padding-bottom: $value !important; }
}
